import React from 'react';
import HomepageLayout from './HomepageLayouts';

const App = () => {

  return (
    <div>
      <HomepageLayout />
    </div>
  );
}

export default App;
